<template>
	<div>
		<div class="row tab-view" v-show="confirmKeywords">
			<div class="row search-title">搜索结果：{{ confirmKeywords }}</div>
		</div>
		<div style="height: 62px;"></div>
		<div class="main">
			<div class="list">
				<template v-for="(item, index) in list">
					<reward :item="item" :showImg="true"></reward>
				</template>
			</div>
			<div class="paginate-view">
				<div class="row cc loading-view" v-show="loading && !list.length == 0">加载中……</div>
				<div class="skeleton-view" v-show="loading && list.length == 0">
					<el-skeleton animated class="skeleton-item" />
					<el-skeleton animated class="skeleton-item" />
					<el-skeleton animated class="skeleton-item" />
				</div>
				<div v-show="!loading && list.length == 0"><el-empty description="暂无搜索结果"></el-empty></div>
				<div class="row cc pagination" v-show="total > 0">
					<el-pagination background layout="prev,pager,next" :current-page="page" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import reward from '@/pc/components/ask/list/reward.vue';
export default {
	data() {
		return {
			keywords: '',
			confirmKeywords: '',
			page: 1,
			pageSize: 15,
			list: [],
			loading: false,
			total: 0
		};
	},
	components: {
		reward
	},
	computed: {
		...mapState(['majorInfo'])
	},
	created() {
		this.keywords = this.confirmKeywords = this.$route.query.keywords;
		this.loadData();
		this.$app.$on('confirmSearch', keywords => {
			this.confirmKeywords = keywords;
			this.loadData();
		});
	},
	destroyed() {
		this.$app.$off('confirmSearch');
	},
	methods: {
		pageChange(e) {
			this.list = [];
			this.page = e;
			this.loadData();
		},
		async loadData() {
			console.log(1)
			if (!this.loading) {
				window.scroll(0, 0);
				this.loading = true;
				let res = await this.$service.post('home/searchQue', {
					// major_id: this.majorInfo.major_id,
					page: this.page,
					pageSize: this.pageSize,
					keywords: this.confirmKeywords
				});
				this.total = res.data.total;
				this.list = res.data.data;
				this.loading = false;
			}
		}
	}
};
</script>

<style>
.el-popover {
	min-width: 74px;
}
</style>
<style scoped lang="less">
@import './index.less';
</style>
